<template>
	<div v-if="isAdmin">
		<slot></slot>
	</div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useUserStore } from '../../../stores/userStore';

const UserStore = useUserStore();

const isAdmin = computed(() => UserStore.userRole === 'admin');
</script>
